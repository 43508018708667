<template>
  <v-container fluid>
    <v-card tile>

      <v-toolbar>
        <v-btn icon :to="`/admin/${path}`" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Edit reservation item</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container v-if="loading">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
              Loading data
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-form v-else @submit.prevent="saveItem">
          <v-text-field v-model="item.name" label="Name"></v-text-field>
          <v-text-field v-model="item.roomNumber" label="Room number"></v-text-field>
          <v-text-field v-model="item.email" label="E-mail"></v-text-field>
          <v-text-field v-model="item.descriptionText" label="Description text"></v-text-field>
          <v-text-field v-model="item.maxConcurrency" label="Max concurrency" type="number"></v-text-field>
          <v-text-field v-model="item.maxDuration" label="Max duration" type="number"></v-text-field>
          <v-text-field v-model="item.availabilityStart" label="Availability start" prefix="Hour:" type="number"></v-text-field>
          <v-text-field v-model="item.availabilityEnd" label="Availability end" prefix="Hour:" type="number"></v-text-field>
          <v-btn type="submit" color="success">Save</v-btn>
          <v-btn text @click="$router.push(`/admin/${path}`)">Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      item: null,
      path: 'reservation_items'
    }
  },
  methods: {
    async saveItem () {
      await this.$store.dispatch('rest/updateItem', {
        path: this.path,
        item: this.item
      })
      await this.$router.push(`/admin/${this.path}`)
    }
  },
  async mounted () {
    document.title = 'Edit reservation item | Masarka Student Club'
    this.loading = true
    this.item = await this.$store.dispatch('rest/fetchItemById', {
      path: this.path,
      id: this.$route.query.id
    })
    this.loading = false
  }
}
</script>
